import PageHeader from '@/components/PageHeader.vue'
import $post from '@/request/productApis.js'
import $comjs from '@/minxin/commonJs.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import Upload from '@/components/productUpload.vue'
const editCertifiList = {
  name: 'editCertifiList',
  components: {
    PageHeader,
    ElImageViewer,
    Upload
  },
  props: {
    IDX: {
      type: Number,
      default: 0
    }, // 产品id
    RequestType: {
      type: Number,
      default: 0
    } // 1： 首营  2：列表
  },
  data() {
    return {
      ProductfilelistLoading: false,
      editProductLoading: false,
      // 行高改变的一些字段
      targetTd: null,
      coltargetTd: null,
      resizeable: false,
      mousedown: false,
      // 裁剪组件的基础配置option
      option: {
        view_image: '',
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 500, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [7, 5], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      options: [],
      dialogTableVisible: false,
      materialdetaisinfo: {
        IDX: 0,
        MaterialName: '',
        NickName: '',
        InvCode: '',
        MaterialTypeName: '',
        InvTypeName: '',
        BrandID: '',
        Specification: '',
        StockUnitN: '',
        MinPacking: '',
        RegisterNo: '',
        RegisterStart: '',
        RegisterEnd: '',
        ProductFactory: '',
        ShelfLifeNum: '',
        ShelfLifeTypeStr: '',
        TransportCondition: '',
        ProductLicenseNo: '',
        SourceAreaName: '',
        StorageNo: '',
        MaterialCertificateList: [
          //   {
          //   IDX: '主键',
          //   CertificateType: '证件类别的 Code的值',
          //   CertificateTypeName: '产品证照名称',
          //   DatetimeType: '证照是否是长期有效期状态 0：否，1：是',
          //   CertificateStartLine: '证照起始日期',
          //   CertificateDeadLine: '证照结束日期',
          //   CertificatePath: '证相路径',
          //   CertificateName: '注册证号',
          //   CertificateCompanyName: '注册证企业名称'
          // }
        ]
      },
      LicenseIDX: '', // 产品id
      firstCampProductID: '',
      types: [], // 证照类型
      listImgs: [],
      certificateInfo: {}, // 证照信息
      showViewer: false, // 查看图片
      MaterialFileList: [],
      checkedcetificasidx: [], // 选中的证照
      LaunchApprovalloading: false, // 发起审核时的按钮加载
      row_index: -1 // 编辑中的index
    }
  },
  mounted() {
    if (this.IDX && this.IDX > 0) {
      this.getProductDetaisInfo(this.IDX)
    }
    this.getTypes()
  },
  methods: {
    save() {
      this.$parent.SaveOrLanch = 1
      this.$parent.$refs.MatetialEdit.save()
    },
    LaunchApproval1() {
      this.$parent.SaveOrLanch = 2
      this.$parent.$refs.MatetialEdit.save()
    },
    reLaunchApproval1() {
      this.$parent.SaveOrLanch = 3
      this.$parent.$refs.MatetialEdit.save()
    },
    // 编辑证照
    edtiFile(_row, _index) {
      const _this = this
      _this.row_index = _index
      _this.ProductfilelistLoading = true
      $post.GetFirstCampFiles({ FileURL: _this.b2bstaticUrl + _row.CertificatePath }).then((res) => {
        _this.ProductfilelistLoading = false
        if (res.RetCode === '0') {
          _this.editfileReady(_this, _row, 'data:image/jpeg;base64,' + res.Message)
        } else {
          _this.$message('获取证照信息出错：' + res.RetMsg)
        }
      })
    },
    editfileReady(_this, _row, base64str) {
      _this.$refs.Upload.idx = _row.IDX
      _this.$refs.Upload.Certicificaitem = {
        Code: _row.CertificateType,
        Sort: _row.Sort
      }
      if (!_this.materialdetaisinfo.MaterialCertificateList || _this.materialdetaisinfo.MaterialCertificateList.length < 1) {
        _this.materialdetaisinfo.MaterialCertificateList = []
      }
      console.log(_row)
      _this.$refs.Upload.form = {
        Remark: _row.Remark,
        Code: _row.CertificateType, // 证照类型code
        CertificateCode: _row.CertificateName,
        CompanyName: _row.CertificateCompanyName,
        beginDate: _row.CertificateStartLine,
        ValidTo: _row.CertificateDeadLine,
        checked: _row.IsLongRange,
        Base64String: base64str,
        CertificateName: _row.CertificateTypeName,
        FileExtName: _row.CertificatePath.substring(_row.CertificatePath.lastIndexOf('.')),
        CertificatePath: _row.CertificatePath // 新增 上传路劲
      }
      // _this.$refs.Upload.option.imgurl = _this.b2bstaticUrl + _row.CertificatePath
      _this.$refs.Upload.option.img = _this.b2bstaticUrl + _row.CertificatePath
      _this.$refs.Upload.option.default = 0
      _this.dialogTableVisible = true
    },
    // 单个下载证照
    downonefile(_row) {
      const _this = this
      _this.ProductfilelistLoading = true
      // 截取文件后缀
      const _filename = _row.CertificatePath.substring(_row.CertificatePath.lastIndexOf('.'))
      $post.GetFirstCampFiles({ FileURL: this.b2bstaticUrl + _row.CertificatePath }).then((res) => {
        _this.ProductfilelistLoading = false
        if (res.RetCode === '0') {
          $comjs.downloadFile(res.Message, $comjs.guid() + _filename)
        }
      })
    },
    // 发起审核
    LaunchApproval() {
      this.LaunchApprovalloading = true
      // this.$parent.$refs.MatetialEdit.editProductLoading = true
      // this.$parent.SaveOrLanch = 2
      this.$api.LaunchApproval({
        IDX: this.materialdetaisinfo.IDX,
        ApprovalProcessType: 2 // 产品
      }).then(res => {
        this.LaunchApprovalloading = false
        if (res.RetCode === '0') {
          this.$alert('发起审核成功', '提示', {
            confirmButtonText: '确定',
            type: 'success',
            callback: action => {
              this.$router.push({ name: 'ProductList1' })
            }
          })
          return
        }
        if (res.RetMsg === '未设置审批流程') {
          this.$alert(res.RetMsg, '提示', {
            confirmButtonText: '去配置',
            cancelButtonText: '退出',
            showCancelButton: true,
            type: 'error'
          }).then(res => {
            this.$router.push({ name: 'approval' })
          }).catch(() => {
            this.$router.push({ name: 'ProductList1' })
          })
          return
        }
        return this.$message.error('发起审核失败')
      })
    },
    // 重新发起审核
    reLaunchApproval() {
      this.$parent.$refs.MatetialEdit.editProductLoading = true
      this.$api.RelaunchApproval({
        IDX: this.materialdetaisinfo.RecordID
      }).then(res => {
        this.$parent.$refs.MatetialEdit.editProductLoading = false
        if (res.RetCode === '0') {
          this.$alert('重新发起审核成功', '提示', {
            confirmButtonText: '确定',
            type: 'success',
            callback: action => {
              this.$router.push({ name: 'ProductList' })
            }
          })
          return
        }
        return this.$message.error('重新发起审核失败')
      })
    },
    MaterialCertificateListhandleSelectionChange(val) {
      console.log(val)
      this.checkedcetificasidx = []
      if (val) {
        val.forEach((ele) => {
          this.checkedcetificasidx.push(ele.IDX)
        })
      }
    },
    // 单个删除证照
    confirmdeleteonefile(_idx) {
      const _this = this
      _this.$confirm('确认删除证照吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (_idx <= 0) {
          _this.RemoveCetrificate(_idx)
          _this.$message('操作成功')
        } else {
          _this.DelteCertifi(_idx)
        }
      }).catch(() => {
      })
    },
    // 批量删除证照
    comfirmDelteCertifi() {
      const _this = this
      if (!_this.checkedcetificasidx || _this.checkedcetificasidx.length < 1) {
        _this.$message('请选择至少一行数据')
        return false
      }
      _this.$confirm('确认删除选中的证照吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.DelteCertifi(-1)
      }).catch(() => {
      })
    },
    DelteCertifi(_idx) {
      const _this = this
      // _this.ProductfilelistLoading = true
      _this.$parent.$refs.MatetialEdit.editProductLoading = true
      let _Idxs = ''
      if (_idx > 0) {
        _Idxs = _idx
      } else {
        _Idxs = _this.checkedcetificasidx.join(',')
      }
      if (_this.RequestType === 1) {
        $post.DeleAddMaterCertificateInfo({ Idx: _Idxs, FirstCamp_MaterialInfoID: _this.materialdetaisinfo.IDX }).then((res) => {
          _this.$parent.$refs.MatetialEdit.editProductLoading = false
          if (res.RetCode === '0') {
            _this.RemoveCetrificate(_Idxs.toString())
            // this.getProductDetaisInfo(_this.materialdetaisinfo.IDX)
            _this.$message('操作成功')
          } else {
            _this.$message('操作失败：' + res.RetMsg)
          }
        })
      } else if (_this.RequestType === 2) {
        // , FirstCamp_MaterialInfoID: _this.materialdetaisinfo.IDX
        $post.DeleMaterCertificateInfo({ FCMIdx: _Idxs }).then((res) => {
          _this.$parent.$refs.MatetialEdit.editProductLoading = false
          if (res.RetCode === '0') {
            // this.getProductDetaisInfo(_this.IDX)
            _this.RemoveCetrificate(_Idxs.toString())
            _this.$message('操作成功')
          } else {
            _this.$message('操作失败：' + res.RetMsg)
          }
        })
      }
    },
    // 移除项
    RemoveCetrificate(idxs) {
      for (let i = 0; i < this.materialdetaisinfo.MaterialCertificateList.length; i++) {
        if (idxs.indexOf(this.materialdetaisinfo.MaterialCertificateList[i].IDX) > -1) {
          this.materialdetaisinfo.MaterialCertificateList.splice(i, 1)
          i = i - 1
        }
      }
    },
    viewPic() {
      this.materialdetaisinfo.MaterialCertificateList.forEach(item => {
        this.MaterialFileList.push(this.b2bstaticUrl + item.CertificatePath)
      })
      this.showViewer = true
    },
    // 关闭预览
    showViewerClose() {
      this.showViewer = false
    },
    getTypes() {
      const _this = this
      $post.getFileTypes().then((res) => {
        _this.types = res.Message
        console.log(_this.types)
      })
    },
    Turn(url) {
      this.$router.push(url)
    },
    uoLoad() {
      this.$refs.Upload.idx = 0
      this.row_index = -1
      this.$refs.Upload.form = {
        Remark: '',
        Code: null, // 证照类型code
        CertificateCode: '',
        CertificateName: '',
        CompanyName: '',
        beginDate: null,
        ValidTo: null,
        checked: false,
        FileExtName: ''
      }
      if (!this.materialdetaisinfo.MaterialCertificateList || this.materialdetaisinfo.MaterialCertificateList.length < 1) {
        this.materialdetaisinfo.MaterialCertificateList = []
      }
      this.$refs.Upload.option.img = ''
      this.dialogTableVisible = true
    },
    cancels() {
      // this.getProductDetaisInfo(this.firstCampProductID)
      this.$refs.Upload.idx = 0
      this.row_index = -1
      this.dialogTableVisible = false
    },
    getProductDetaisInfo(_idx) {
      const _this = this
      _this.ProductfilelistLoading = true
      if (_this.RequestType === 1) {
        $post.getProductInfo({ FCMIdx: _idx }).then(res => {
          _this.ProductfilelistLoading = false
          console.log(res)
          if (res.RetCode === '0') {
            _this.materialdetaisinfo = res.Message
            _this.firstCampProductID = _this.materialdetaisinfo.IDX + ''
            _this.listImgs = res.Message.MaterialCertificateList
          } else {
            _this.$message.error('获取产品信息出错： ' + res.RetMsg)
            _this.$router.push({ name: 'ProductList1' })
          }
        })
      } else if (_this.RequestType === 2) {
        $post.GetMaterialRefCompanyDeatil({ FCMIdx: _idx }).then(res => {
          _this.ProductfilelistLoading = false
          if (res.RetCode === '0') {
            _this.materialdetaisinfo = res.Message
            _this.firstCampProductID = _this.materialdetaisinfo.IDX + ''
            _this.listImgs = res.Message.MaterialCertificateList
          } else {
            _this.$message.error('获取产品信息出错： ' + res.RetMsg)
            _this.$router.push({ name: 'productInfoList1' })
          }
        })
      }
    }
  }
}
export default editCertifiList
