<template>
  <div class="uploads QualificationUpload">
    <el-dialog title="资质文件上传" top="5vh" custom-class="custom-class2" fullscreen :visible.sync="show" @close="() => $emit('cancel',false)">
      <!-- <el-button type="primary" size="mini">清空列表</el-button> -->
      <div v-loading.fullscreen.lock="fullscreenLoading" class="main t_line_s">
        <div class="mid">
          <div class="btns p-tb-10 m-left-20 input_file">
            <!-- <el-button type="primary" size="mini" @click="shibie">自动识别</el-button> -->
            <el-button @click="$refs.cropper.changeScale(2)"><i class="el-icon-zoom-in m-right-5" />放大</el-button>
            <el-button @click="$refs.cropper.changeScale(-2)"><i class="el-icon-zoom-out m-right-5" />缩小</el-button>
            <el-button @click="right"><i class="el-icon-refresh-right m-right-5" />顺旋转</el-button>
            <el-button @click="left"><i class="el-icon-refresh-left m-right-5" />逆旋转</el-button>
            <el-button type="primary" @click="upLoadFile"><i class="el-icon-upload2 m-right-5" />上传</el-button>
            <!-- <input id="file" type="file" name="file" multiple="multiple" @change="handleFileChange"> -->
            <input id="file" ref="filElem" type="file" name="file" multiple="multiple" @change="handleFileChange">
          </div>
          <div class="cropper-content">
            <div class="cropper" style="text-align:center">
              <vueCropper
                ref="cropper"
                :img="option.img"
                :output-size="option.size"
                :output-type="option.outputType"
                :info="true"
                :full="option.full"
                :can-move="option.canMove"
                :can-move-box="option.canMoveBox"
                :original="option.original"
                :auto-crop="option.autoCrop"
                :fixed="option.fixed"
                :fixed-number="option.fixedNumber"
                :center-box="option.centerBox"
                :info-true="option.infoTrue"
                :fixed-box="option.fixedBox"
              />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="gaodu" />
          <div class="RightInfo">
            <div class="item flex">
              <div style="width:100px; text-align: left;padding: 5px;background-color: #fafafa;"><span style="color:red;margin-right:5px">*</span>证件名称</div>
              <div style="flex:1">
                <el-select v-model="form.Code" style="width:100%" size="small" placeholder="请选择" @change="isChange">
                  <el-option
                    v-for="item1 in types"
                    :key="item1.IDX"
                    :label="item1.Name"
                    :value="item1.Code"
                  />
                </el-select>
              </div>
            </div>
            <div v-if="Certicificaitem.Sort == 1 " class="item flex m-tb-20">
              <div class="name"><span style="color:red;margin-right:5px">*</span>企业名称</div>
              <el-input v-model="form.CompanyName" size="mini" class="rule-input-edit" style="border:none" />
            </div>
            <div v-if="Certicificaitem.Sort == 1 " class="item flex">
              <div class="name"><span style="color:red;margin-right:5px">*</span>证件编号</div>
              <el-input v-model="form.CertificateCode" size="mini" class="rule-input-edit" style="border:none" />
            </div>
            <div v-if="Certicificaitem.Sort == 1 " class="item flex  m-tb-20">
              <div style="width:100px; text-align: left;padding: 5px;background-color: #fafafa;"><span style="color:red;margin-right:5px">*</span>证件起始日期</div>
              <div class="flex a-center" style="flex:1">
                <el-date-picker
                  v-model="form.beginDate"
                  type="date"
                  size="mini"
                  style="width:100%"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                />
              </div>
            </div>
            <div v-if="Certicificaitem.Sort == 1 " class="item flex  m-tb-20">
              <div style="width:100px; text-align: left;padding: 5px;background-color: #fafafa;"><span style="color:red;margin-right:5px">*</span>证件有效期至</div>
              <div class="flex a-center">
                <el-date-picker
                  v-model="form.ValidTo"
                  type="date"
                  size="mini"
                  style="width:100%"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                />
                <el-checkbox v-model="form.checked" class="m-left-5 p-lr-5" @change="longcheck">长期有效</el-checkbox>
              </div>
            </div>
            <div class="item flex m-tb-20" :class="Certicificaitem.Sort === 2 ? 'm-tb-20' :'' ">
              <div style="width:100%">
                <div class="p-tb-5" style="background:#fafafa;width:100%;"><i class="p-left-10" />备注信息</div>
                <el-input v-model="form.Remark" size="mini" type="textarea" class="rule-input-edit" style="border:none" />
              </div>
            </div>
            <div class="caozuo t-right">
              <el-button @click="$emit('cancel',false) ">取消</el-button>
              <el-button type="primary" @click="save">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import $post from '@/request/productApis.js'
export default {
  name: 'Upload',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    types: {
      type: Array,
      default: () => []
    },
    firstCampProductID: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => {}
    },
    listImgs: {
      type: Array,
      default: () => {}
    },
    certificateInfo: {
      type: Object,
      default: () => {}
    },
    RequestType: {
      type: Number,
      default: 0
    } // 1： 首营  2：列表
  },
  data() {
    return {
      idx: 0,
      // listImgs: [],
      fullscreenLoading: false,
      value: '',
      form: {
        Remark: '',
        Code: null, // 证照类型code
        CertificateCode: '',
        CertificateName: '',
        CompanyName: '',
        beginDate: null,
        ValidTo: null,
        checked: false,
        FileExtName: '',
        fileUrl: ''
      },
      Certicificaitem: {},
      // 裁剪组件的基础配置option
      option: {
        view_image: '',
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: false, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 500, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        // fixedNumber: [7, 5], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        default: 0
      }
    }
  },
  watch: {
    info: {
      handler(a) {
        this.form = a
        this.value = a.CertificateName
        this.listImgs = []
        this.listImgs.push({ Url: `${this.b2bstaticUrl}` + a.PhotoUrl })
        console.log(this.listImgs)
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    // 上传
    upLoadFile() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    isChange(e) {
      this.types.map(item => {
        if (item.Code === e) {
          // this.form.Code = item.Code
          this.form.CertificateName = item.Name
          this.Certicificaitem = item
        }
        if (e.Sort !== 1) {
          this.form.CompanyName = ''
          this.form.CertificateCode = ''
          this.form.beginDate = ''
          this.form.ValidTo = ''
        }
      })
    },
    left() {
      this.$refs.cropper.rotateLeft()
    },
    right() {
      this.$refs.cropper.rotateRight()
    },
    shibie() {
      axios({
        // general_basic
        url: '/v1/business_license?access_token=24.cce5cb83fffe72ad3e15188b42997052.2592000.1617352962.282335-23590046',
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          image: this.imagUrl
        },
        transformRequest: [function(data) {
          let ret = ''
          for (const it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }]
      }).then(res => {
        console.log(res)
        this.form.CompanyName = res.data.words_result['单位名称'].words
        this.form.CertificateCode = res.data.words_result['证件编号'].words
      })
    },
    del(e, index) {
      console.log(e)
      // 删除
      this.$api.DeleteFirstCampCompanyCert({
        IDXList: e + ''
      }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.listImgs.splice(index, 1)
        }
      })
    },
    handleFileChange(e) {
      const file = e.target.files[0]
      console.log('afda', file)
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      var reader = new FileReader()
      //   const url = ''
      reader.readAsDataURL(file)
      console.log(file)
      this.form.FileExtName = '.' + file.type.split('/')[1]
      reader.onload = (e) => {
        this.imagUrl = e.target.result
        this.option.img = e.target.result
        this.option.default = 1
        console.log(this.option.img)
      }
      // 清空数据
      this.$refs.filElem.value = ''
    },
    longcheck(val) {
      if (val) {
        this.form.ValidTo = '9999-12-31'
      } else {
        this.form.ValidTo = ''
      }
    },
    save() {
      const _this = this
      if (!_this.form.FileExtName || _this.form.FileExtName.length < 1) {
        _this.$message('请选择证照文件')
        return false
      }
      // 校验数据
      let isgoing = true
      isgoing = _this.checkData()
      if (!isgoing) {
        return false
      }
      _this.fullscreenLoading = true
      console.log('IDX', this.idx)
      // 处理时间
      // const _beginDate = this.form.beginDate
      // const _ValidTo = this.form.ValidTo
      // 处理参数
      const paras = {
        IDX: this.idx,
        // FirstCamp_MaterialInfoID: this.firstCampProductID,
        CertificateName: this.form.CertificateCode, // 注册证号
        CertificateTypeName: this.form.CertificateName,
        CertificateFileName: this.form.CertificateName + this.form.FileExtName, // 注册证文件名
        CertificateType: this.Certicificaitem.Code,
        Sort: this.Certicificaitem.Sort,
        cerEndDate: this.form.ValidTo,
        CertificateDeadLine: this.form.ValidTo,
        CertificateStartLine: this.form.beginDate,
        cerBeginDate: this.form.beginDate,
        CertificateCompanyName: this.form.CompanyName,
        DatetimeType: this.form.checked ? 1 : 0,
        Remarks: this.form.Remark,
        FileExtName: this.form.FileExtName,
        CertificatePath: this.form.CertificatePath
      }
      if (_this.option.default === 0 && _this.$parent.row_index > -1) {
        // 没有更换过图片
        _this.$parent.materialdetaisinfo.MaterialCertificateList.splice(_this.$parent.row_index, 1, paras)
        // _this.$parent.materialdetaisinfo.MaterialCertificateList.push(paras)
        _this.fullscreenLoading = false
        _this.$parent.$data.dialogTableVisible = false
      } else {
        const uploadparas = {
          FileName: _this.form.FileExtName,
          Base64String: _this.option.default === 0 ? _this.form.Base64String : _this.option.img
        }
        // 请求文件保存接口 且 将数据push到 列表中，最后保存一起传入后台
        $post.UploadFirstOrMaterialCertificate(uploadparas).then(res => {
          console.log(res)
          if (res.RetCode !== '0') return this.$message.error(res.RetCode + res.Message)
          if (res.RetCode === '0') {
            paras.CertificatePath = res.Message.substring(res.Message.indexOf('/webupload'))
            // 判断idx 是否存在，存在替换不存在追加
            if (_this.idx && _this.idx > 0) {
              _this.$parent.materialdetaisinfo.MaterialCertificateList.splice(_this.$parent.row_index, 1, paras)
            } else {
              _this.$parent.materialdetaisinfo.MaterialCertificateList.push(paras)
            }
            _this.fullscreenLoading = false
            _this.$parent.$data.dialogTableVisible = false
          }
        })
      }
      // if (_this.RequestType === 1) {
      //   $post.SubmitMaterCertificateInfo(paras).then(res => {
      //     console.log(res)
      //     _this.fullscreenLoading = false
      //     if (res.RetCode !== '0') return this.$message.error(res.RetCode + res.Message)
      //     if (res.RetCode === '0') {
      //       _this.$message.success('保存成功！')
      //       _this.$parent.getProductDetaisInfo(_this.firstCampProductID)
      //       _this.$parent.$data.dialogTableVisible = false
      //     }
      //   })
      // } else if (_this.RequestType === 2) {
      //   $post.SubmitMaterCertificateByRefCompany(paras).then(res => {
      //     console.log(res)
      //     _this.fullscreenLoading = false
      //     if (res.RetCode !== '0') return this.$message.error(res.RetCode + res.Message)
      //     if (res.RetCode === '0') {
      //       _this.idx = 0
      //       _this.$message.success('保存成功！')
      //       _this.$parent.getProductDetaisInfo(_this.firstCampProductID)
      //       _this.$parent.$data.dialogTableVisible = false
      //     }
      //   })
      // }
    },
    checkData() {
      console.log(this.form)
      const _this = this
      if (!_this.form.Code || _this.form.Code < 0) {
        _this.$message.error('请选择证照类型')
        return false
      }
      if (_this.Certicificaitem.Sort === 1) {
        if (!_this.form.CompanyName || _this.form.CompanyName.length < 1) {
          _this.$message.error('请填写企业名称')
          return false
        }
        if (!_this.form.CertificateCode || _this.form.CertificateCode.length < 1) {
          _this.$message.error('请填写证件编号')
          return false
        }
        if (!_this.form.beginDate || _this.form.beginDate.length < 1) {
          _this.$message.error('请选择证照起始日期')
          return false
        }
        if (!_this.form.ValidTo || _this.form.ValidTo.length < 1) {
          _this.$message.error('请选择证照证件有效期')
          return false
        }
      }
      return true
    }
  }
}
</script>
<style lang='scss' scoped>
.uploads{
  .custom-class2{
    .main{
      display: flex;
      justify-content: space-between;
       height:calc(100vh - 20vh);
      .left{
        min-width: 200px;
        padding: 5px;
        overflow:auto;
        .img_view{
            width: 200px;
            height: 200px;
            background-color: #fff;
            border:1px solid #ccc;
            position: relative;
            img{
                width: 100%;
                height: 100%;
            }
            .close___{
                position: absolute;
                right: 5px;
                top: 5px;
                z-index: 9999;
            }
        }
      }
      .left::-webkit-scrollbar {
        display: none;
      }
      .mid{
        flex: 1;
        min-width: 800px;
      }
      .right{
        min-width: 300px;
        .item{
            width: 100%;
            border: 1px solid #ddd;
            .name{
                width:140px;
                text-align: left;
                padding: 5px;
                background-color: #fafafa;
            }
        }
        .item >>> .el-input__inner {
       border: none;
  }

      }
    }
    .el-dialog__body{
      padding: 20px;
    }
    .el-dialog__header{
      padding: 0;
      display: none;
    }
  }
}
  .cropper-content {
      min-width: 500px;
    .cropper {
      //  min-width: 500px;
    //    width: 100%;
       height:calc(100vh - 185px);
    }
}
.rule-input-edit ::v-deep {
  .el-input__inner {
       border: none;
  }
}
.el-select .el-select--small ::v-deep{
    .el-input .el-input--small .el-input--suffix{
        .el-input__inner{
            border: none;
        }
    }
}
</style>
