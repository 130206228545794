<template>
  <div>
     <PageHeader class="zizhititle t_line_s" title="资质文件"></PageHeader>
    <div class="ZizhiwenjianBox main_flex flex j-between" :v-loading="ProductfilelistLoading">
      <div class="left min-border-right flex j-top">
        <div class="t-right p-tb-10 p-right-10 b_line_s">
          <el-button size="mini" @click="uoLoad"><i class="el-icon-edit" />上传证件</el-button>
          <el-button size="mini" @click="comfirmDelteCertifi">批量删除</el-button>
          <!-- <el-button v-if="RequestType ===1 && materialdetaisinfo.Status === 0" type="primary" size="mini" :loading="LaunchApprovalloading" @click="LaunchApproval">发起审核</el-button> -->
          <!-- <el-button v-if="RequestType ===1 && materialdetaisinfo.Status === 3" type="primary" size="mini" :loading="LaunchApprovalloading" @click="reLaunchApproval">重新发起</el-button> -->
          <!-- <el-button v-if="RequestType ===2" size="mini" type="primary" @click="$router.push({ name: 'productInfoList1' })">保存并返回</el-button>
          <el-button v-if="RequestType ===1" size="mini" type="primary" @click="$router.push({ name: 'ProductList1' })">保存并返回</el-button> -->
        </div>
        <div v-if="materialdetaisinfo.MaterialCertificateList === null || materialdetaisinfo.MaterialCertificateList.length < 1" class="uploaddiv m-lr-30">
          <i class="el-icon-upload" style="font-size:30px" />
          <div class="el-upload__text" style="font-size:12px">
            <el-link type="primary" :underline="false" style="font-size:12px" @click="uoLoad">点击上传文件</el-link>
            <br>
            支持扩展名 .jpg .png .pdf等文件类型 大小2mb以内</div>
        </div>
        <div class="left_list">
          <div v-for="(item,index) in materialdetaisinfo.MaterialCertificateList" :key="index" class="img_view">
            <img :src="(b2bstaticUrl + '/'+item.CertificatePath)" @click="viewPic">
          </div>
        </div>
      </div>
      <div class="right">
        <div class="p-top-10">
          <el-table
            :border="false"
            :data="materialdetaisinfo.MaterialCertificateList"
            max-height="300px"
            width="100%"
            @selection-change="MaterialCertificateListhandleSelectionChange"
          >
            <el-table-column
              fixed="left"
              type="selection"
              align="center"
              width="42"
            />
            <el-table-column
              prop="CertificateTypeName"
              label="证件类型"
              width="180"
            />
            <el-table-column
              prop="CertificateCompanyName"
              label="企业名称"
              width="180"
            />
            <el-table-column
              prop="CertificateName"
              label="证件编号"
            />
            <el-table-column
              prop="CertificateDeadLine"
              label="证件有效期"
            >
            <template slot-scope="scope">
               {{$minCommon.formatDate1(scope.row.CertificateDeadLine,'yyyy-MM-dd')}}
            </template>
            </el-table-column>
            <el-table-column
              v-if="RequestType === 2"
              prop="TypeString"
              label="状态"
            />
            <el-table-column
              label="操作"
              width="50"
            >
              <template slot-scope="scope">
                <el-popover
                  placement="left"
                  trigger="hover"
                  :width="50"
                  popper-class="test_pop_view"
                >
                  <div>
                    <div class="min-border-bottom m-tb-10">
                      <i class="el-icon-edit p-right-10" />
                      <span><el-link type="primary" :underline="false" @click="edtiFile(scope.row,scope.$index)">编辑</el-link></span>
                    </div>
                    <div class="m-tb-10">
                      <i class="el-icon-download p-right-10" />
                      <span><el-link type="primary" :underline="false" @click="confirmdeleteonefile(scope.row.IDX)">删除</el-link></span>
                    </div>
                    <div class="m-tb-10">
                      <i class="el-icon-download p-right-10" />
                      <span><el-link type="primary" :underline="false" @click="downonefile(scope.row)">下载</el-link></span>
                    </div>
                  </div>
                  <i slot="reference" class="el-icon-more" />
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
     <div class="caozuo t-right p-right-20 p-top-20 t_line_s">
      <el-button @click="save()" size="mini"><i class="el-icon-edit" />保存草稿</el-button>
      <el-button type="primary" @click="LaunchApproval1"  v-if="(RequestType ===1 && ((materialdetaisinfo.Status === 0 && materialdetaisinfo.ARIStatus !== 2 ) || materialdetaisinfo.IDX <= 0))" :loading="LaunchApprovalloading">发起审核</el-button>
      <el-button  v-if="RequestType ===1 && (materialdetaisinfo.Status === 3 || materialdetaisinfo.ARIStatus === 2)"  type="primary" size="mini"  @click="reLaunchApproval1"  :loading="LaunchApprovalloading">重新发起</el-button>
    </div>
    <Upload ref="Upload" :request-type="RequestType" :certificate-info="certificateInfo" :list-imgs="listImgs" :first-camp-product-i-d="firstCampProductID" :types="types" :show="dialogTableVisible" @cancel="cancels" />
    <el-image-viewer v-if="showViewer" :url-list="MaterialFileList" :on-close="showViewerClose" />
  </div>
</template>
<script>
import editCertifiList from '@/minxin/product/editCertifiList.js'
export default editCertifiList
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/product.scss';
</style>
<style lang='scss'>
</style>
